var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-toolbar", { attrs: { flat: "", height: "30" } }),
      _c("h1", [_vm._v(" Nick's version ")]),
      _c("div", [
        !_vm.production
          ? _c("h4", [_vm._v(" development ")])
          : _c("h4", [_vm._v(" Production ")]),
      ]),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showRescanButton,
              expression: "! showRescanButton",
            },
          ],
        },
        [_vm._v(" " + _vm._s(_vm.message))]
      ),
      _vm.showRescanButton && _vm.production
        ? _c(
            "div",
            [
              _c("VueIp", {
                attrs: { ip: _vm.ip, "on-change": _vm.change, port: _vm.port },
              }),
              _c("v-btn"),
              _c("v-btn", { on: { click: _vm.Add } }, [_vm._v(" add ")]),
            ],
            1
          )
        : _vm._e(),
      _vm.showRescanButton
        ? _c("v-btn", { on: { click: _vm.reScan } }, [_vm._v(" scani2c ")])
        : _vm._e(),
      _c("DataTable", {
        key: _vm.componentKey,
        ref: "table",
        attrs: {
          "default-options": {
            sortBy: ["lastModifyTime"],
            sortDesc: [true],
          },
          headers: _vm.headers,
          "load-data": _vm.loadData,
          "item-key": "id",
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.number`,
              fn: function ({ index }) {
                return [_vm._v(" " + _vm._s(index + 1) + " ")]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleConnect(item)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" rowing ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("connect")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-4",
                                        attrs: { color: "blue darken-3" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(item)
                                          },
                                        },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" edit ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("information")])]
                  ),
                  _vm.production
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { id: "delete" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-4",
                                            attrs: { color: "blue darken-3" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(item)
                                              },
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" delete ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("delete")])]
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("AntSchema", {
        ref: "antSchema",
        on: {
          addSuccess: _vm.handleAddSuccess,
          editSuccess: _vm.handleEditSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }