var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ip-input-container" },
    _vm._l(_vm.segments, function (segment, index) {
      return _c("div", { staticClass: "ip-segment" }, [
        _c("input", {
          staticClass: "ip-segment-input",
          attrs: { type: "text", maxlength: "3", placeholder: _vm.placeholder },
          domProps: { value: segment },
          on: {
            keydown: function ($event) {
              return _vm.onInputKeydown($event, index)
            },
            input: function ($event) {
              return _vm.onInput($event, index)
            },
            blur: function ($event) {
              return _vm.onInputBlur()
            },
            paste: function ($event) {
              return _vm.onPaste($event, index)
            },
          },
        }),
        _c(
          "i",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: index != _vm.segments.length - 1,
                expression: "index != segments.length - 1",
              },
            ],
          },
          [_vm._v(".")]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }